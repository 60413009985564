import { useTranslation } from "react-i18next";
import { PetNameDefaultImage } from "ui-2";
import { formatBirthday } from "utils";
import { PetType } from "../../../types";
import { birthdayIcon, genderIcon, weightIcon, editIcon2 } from "../../icons";
import { MY_PETS, ROUTES_PATH } from "../../constants";
import { BreedType } from "../../../types";
import useSearchPersistantNavigate from "../../hooks/useSearchPersistantNavigate";
import { useMyPets } from "../../hooks/useMyPet";
import GTM_ACTIONS from "../../config/gtmActions";
import { datalayerPush } from "../../lib/gtm";
import { useMemo } from "react";
import { getPetGenderName, getPetSizeName } from "../../lib/common";

const BasicInfoCard = ({
  petData,
}: {
  petData: PetType & { breedData?: BreedType };
}) => {
  const { t } = useTranslation();
  const searchPersistantNavigate = useSearchPersistantNavigate();
  const { isViewRememberedPets } = useMyPets();

  const onEdit = () => {
    datalayerPush(GTM_ACTIONS.MY_PETS_PROFILE_EDIT_A_PET);
    searchPersistantNavigate(`${ROUTES_PATH.EDIT_PET}`, {
      state: petData,
    });
  };

  const petGenderName = useMemo(() => {
    return getPetGenderName(petData?.petGenderId ?? 0);
  }, [petData?.petGenderId]);

  const petSizeName = useMemo(() => {
    return getPetSizeName(petData?.petTypeId ?? 0, petData?.petSizeId ?? 0);
  }, [petData?.petTypeId, petData?.petSizeId]);

  return (
    <div
      aria-label="basic-info-card"
      data-testid="basic-info-card"
      className="flex w-full flex-col gap-2"
    >
      <div className=" flex flex-row items-center justify-between self-stretch">
        <div className="label2 lg:headline5">{t(MY_PETS.PROFILE.LABEL1)}</div>
        {!isViewRememberedPets && (
          <button
            data-testid="edit-button"
            aria-label="edit button"
            onClick={() => onEdit()}
            className={`label2 lg:label1 flex cursor-pointer items-center justify-center gap-1 text-[#0054A4]`}
          >
            <div className="h-[18px] w-[18px] fill-[#0054A4] lg:h-[22px] lg:w-[22px]">
              {editIcon2}
            </div>
            <div>{t(MY_PETS.PROFILE.EDIT)}</div>
          </button>
        )}
      </div>
      <div className="flex w-full flex-col gap-3 rounded-lg p-0 shadow-dp2 lg:gap-2">
        <div className="w-full bg-brand-color-library-gray-100">
          <div className="flex w-full gap-4 p-4">
            <div className="h-12 w-12 lg:h-14 lg:w-14">
              {petData.petImage ? (
                <img
                  aria-label="pet-image"
                  src={petData.petImage}
                  alt={petData.petName}
                  className="h-12 w-12 rounded-full object-cover lg:h-14 lg:w-14"
                />
              ) : (
                PetNameDefaultImage(petData.petName)
              )}
            </div>
            <div className="flex flex-col gap-1 lg:gap-2">
              <div className="headline5 lg:headline4">{petData.petName}</div>
              <div className="body3 lg:body2 text-[#66676B]">
                {petData?.breedData
                  ? t(`petBreeds.${petData?.breedData?.petBreedName}`)
                  : t(MY_PETS.PROFILE.NO_BREED)}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-start space-x-1 p-4 text-[#66676B]">
          <div className="body3 lg:body2 flex w-[64px] flex-grow flex-col lg:min-w-[140px] lg:flex-row lg:items-center lg:gap-2">
            <div className="flex h-6 w-6 items-center justify-center fill-[#66676B] lg:h-8 lg:w-8">
              <div className="w-full h-full">
                {birthdayIcon}
              </div>
            </div>
            <div>
              {formatBirthday(
                petData.petBirthday ?? "",
                petData.petBirthdateTypeId,
                window.hph_locale
              )}
            </div>
          </div>
          <div className="body3 lg:body2 flex w-[64px] flex-grow flex-col lg:min-w-[140px] lg:flex-row lg:items-center lg:gap-2">
            <div className="flex h-6 w-6 items-center justify-center fill-[#66676B] lg:h-8 lg:w-8">
              <div className="w-full h-full">
                {genderIcon}
              </div>
            </div>
            <div>{petGenderName}</div>
          </div>
          <div className="body3 lg:body2 flex w-[64px] flex-grow flex-col lg:min-w-[140px] lg:flex-row lg:items-center lg:gap-2">
            <div className="flex h-6 w-6 items-center justify-center fill-[#66676B] lg:h-8 lg:w-8">
              <div className="w-full h-full">
                {weightIcon}
              </div>
            </div>
            <div>{petSizeName}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfoCard;
