import { useEffect, useMemo } from "react";
import NoPetsWrapper from "./components/NoPets";
import { Loader } from "ui-2";
import PetsWrapper from "./components/PetsWrapper";
import { PET_STATUS } from "../../constants";
import { PetType } from "../../../types";
import { useMyPets } from "../../hooks/useMyPet";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const MyPets = () => {
  const {
    isViewRememberedPets,
    setShowRememberedPetsLabel,
    setIsViewRememberedPets,
  } = useMyPets();
  const {
    status: { data: status, isPending, isSettled },
  } = useMyPets();
  const fetchedPetList = status?.data?.data || [];

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const petId = searchParams.get("petId");
  const location = useLocation();
  const isAddPet = location.pathname.includes("add-pet");

  const petList = useMemo(() => {
    const rememberedPets = fetchedPetList.filter(
      (pet: PetType) => pet.petStatusId === PET_STATUS.REMEMBERED
    );
    const activePets = fetchedPetList.filter(
      (pet: PetType) => pet.petStatusId === PET_STATUS.ACTIVE
    );
    return {
      petsToShow: isViewRememberedPets ? rememberedPets : activePets,
      rememberedPets,
    };
  }, [fetchedPetList, isViewRememberedPets]);

  useEffect(() => {
    const hasPets = petList.petsToShow.length > 0;
    const rememberedPets = petList.rememberedPets.length;
    if (!isAddPet) {
      if (rememberedPets > 0) {
        setShowRememberedPetsLabel(true);
      } else {
        setShowRememberedPetsLabel(false);
      }
      const isPetNotInList = !petList.petsToShow.some(
        (pet: PetType) => pet.petId === petId
      );
      if (hasPets && isPetNotInList) {
        navigate(`/mypets/?petId=${petList.petsToShow[0].petId}`);
      }
    } else if (hasPets) {
      navigate(`/mypets/add-pet?petId=${petList.petsToShow[0].petId}`);
    }
  }, [petId, fetchedPetList, isViewRememberedPets]);

  // After reloading, if the user is in the remembered pets view,
  // we want to make sure that there actually is data to show to them
  // so we don't leave them in a locked UI state. If there's no data,
  // take them back to the My Pets view
  useEffect(() => {
    if (isSettled && isViewRememberedPets && !petList.rememberedPets.length) {
      setIsViewRememberedPets(false);
    }
  }, [isSettled]);

  if (isPending) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col">
      {petList.petsToShow.length ? (
        <PetsWrapper petsData={petList.petsToShow} />
      ) : (
        <NoPetsWrapper />
      )}
    </div>
  );
};

export default MyPets;
