import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Loader, Tab, Tabs } from "ui-2";
import { usePromise } from "utils";
import { fetchPetById } from "../../../../api-calls/fetchPetById";
import GTM_ACTIONS from "../../../../config/gtmActions";
import { MY_PETS } from "../../../../constants/index";
import { datalayerPush } from "../../../../lib/gtm";
import BasicInfoCardWrapper from "../BasicInfoCardWrapper/BasicInfoCardWrapper";
import Diet from "../Diet";
import Milestones from "../Milestones";
import PetsNameHeadingWrapper from "../PetsNameHeadingWrapper";
import DeceasedPetComponent from "../DeceasedPetComponent";
import { useSnowFlakePets } from "@hooks/useSnowflakeData";
import { isTheSamePet } from "@lib/common";
import { PetStatusEnum } from "../../../../types/pets";

const tabNames = ["Profile", "Milestones", "Diet"];
const tabNameToIndex = (tabName: string) => tabNames.indexOf(tabName);
const indexToTabName = (index: number) => tabNames[index];

const PetDetails = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const petId = searchParams.get("petId");
  const activeTab = searchParams.get("tab") ?? "Profile";
  const [{ isPending, data }, { exec: fetchPet }] = usePromise(fetchPetById);
  const [loader, setLoader] = useState(false);
  const { snowflakePets } = useSnowFlakePets();
  const { rememberedPets } = snowflakePets;

  const fetchActivePet = useCallback(() => {
    petId && fetchPet(petId);
  }, [petId, fetchPet]);

  useEffect(() => {
    fetchActivePet();
  }, [fetchActivePet]);

  const handleTabChange = useCallback(
    function handleTabChange(index: number) {
      const tabName = indexToTabName(index);
      trackTabClick(tabName);
      setSearchParams({ petId: petId ?? "", tab: tabName });
    },
    [setSearchParams]
  );

  if (isPending || loader) {
    return <Loader />;
  } else {
    const pet = data?.[0];

    if (pet) {
      const isRememberedPet = rememberedPets?.some(
        (rememberedPet) =>
          isTheSamePet(rememberedPet, pet) &&
          pet.petStatusId === PetStatusEnum.ACTIVE
      );

      return (
        <div className="flex flex-col">
          <PetsNameHeadingWrapper petData={pet} setLoader={setLoader} />
          {isRememberedPet ? (
            <DeceasedPetComponent petId={pet.petId} petName={pet.petName} />
          ) : (
            <div data-testid="tabs-component">
              <Tabs
                classes="w-full px-5 lg:px-0 lg:pt-0"
                activeIndex={tabNameToIndex(activeTab)}
                onActiveIndexChange={handleTabChange}
              >
                <Tab title={t(MY_PETS.PROFILE.HEADING)}>
                  <div className="mt-6 flex flex-col items-center justify-center gap-8">
                    <BasicInfoCardWrapper petData={pet} />
                  </div>
                </Tab>
                <Tab title={t(MY_PETS.MILESTONES.HEADING)}>
                  <div className="mt-6 flex items-center justify-center gap-8">
                    <Milestones
                      petId={pet.petId}
                      petType={pet.petType}
                      lifestageId={Number(pet.petLifeStageId)}
                      petName={pet.petName}
                    />
                  </div>
                </Tab>
                <Tab title={t(MY_PETS.DIET.HEADING)}>
                  <div className="mt-6 flex items-center justify-center gap-8">
                    <Diet pet={pet} refetchPet={fetchActivePet} />
                  </div>
                </Tab>
              </Tabs>
            </div>
          )}
        </div>
      );
    } else {
      return <div>Error</div>;
    }
  }
};

function trackTabClick(tabName: string) {
  datalayerPush(GTM_ACTIONS.VIEW_TAB, {
    click_text: tabName,
    action:
      tabName === "Milestones"
        ? "milestone_click"
        : GTM_ACTIONS.VIEW_TAB.action,
  });
}

export default PetDetails;
